const truncateWithEllipsis = (str, length = 10, start = 5, end = 8) => {
  if (!str) return ''
  if (str.length <= length) return str

  const firstPart = str.substring(0, start)
  const lastPart = str.substring(str.length - end, str.length)

  return `${firstPart}...${lastPart}`
}

export { truncateWithEllipsis }
